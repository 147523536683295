import request from '@/utils/request'
// import md5 from "js-md5"
// home页面描述信息及数据库统计结果
export function basicInfo(url) {
    return request({
        url: url+'/mongo/basicInfo/',
        // url: '/mongo/basicInfo/',
        method: 'get',
        // params:data,//get请求参数
    })
}
//轮播图
export function banner(url) {
    return request({
        url: url+'/mongo/bg_img/',
        // url: '/mongo/bg_img/',
        method: 'get',
        // params:data,//get请求参数
    })
}
// download数据下载
export function download(url) {
    return request({
        // url: '/mongo/download/',
        url: url+'/mongo/download/',
        method: 'get',
        // params:data,//get请求参数
    })
}
// blast 任务接口参数
export function blastParams(url) {
    return request({
        // url: '/mongo/blast_params/',
        url: url+'/mongo/blast_params/',
        method: 'get',
        // params:data,//get请求参数
    })
}
//jbrowse
export function jbrowse(url) {
    return request({
        url: url+'/mongo/jbrowse/',
        // url: '/mongo/jbrowse/',
        method: 'get',
        // params:data,//get请求参数
    })
}
//search
/* export function searchFun(url,param) {
    return request({
        url: url,
        method: 'get',
        params: param,
    })
} */
export function searchFun(data,url) {
    return request({
        // url: '/mongo/function',
        url: url+'/mongo/function/',
        method: 'get',
        params:data,//get请求参数
    })
}
//seq例子
export function seqEg(url) {
    return request({
        url: url+'/mongo/seq_fetch_eg/',
        // url: '/mongo/seq_fetch_eg',
        method: 'get',
        // params:data,//get请求参数
    })
}
//pathway
export function map(url,data) {
    return request({
        url: url+'/mongo/map',
        method: 'get',
        params:data,//get请求参数
    })
}
//tools-RepeatMasker
export function te_rm(url,data) {
    return request({
        url: url+'/mongo/te_rm',
        method: 'get',
        params:data,//get请求参数
    })
}
//tools-RepeatProteinMask
export function te_rpm(url,data) {
    return request({
        url: url+'/mongo/te_rpm',
        method: 'get',
        params:data,//get请求参数
    })
}
export function getSearch(url,param) {
    return request({
        url: url,
        method: 'get',
        params: param,
    })
}

export function getFasta(url,data) {
    return request({
        url: url,
        method: 'post',
        data,
    })
}
export function heatmapFile(data,config) {
    return request({
        url: '/fileUpload/heatmap_upload/',
        headers: config.headers,
        method: 'post',
        data,
    })
}


<template>
  <div class="Main" style="height:100%">
    <div class="rightMenuBox">
			<div class="admin">
        <img src="@/assets/dataLogo.png" alt="">
				<el-dropdown-menu class="" slot="dropdown">
					<el-dropdown-item  @click.native="dialogVisible = true">Alter Password</el-dropdown-item>
					<el-dropdown-item  @click.native="logout">Logout</el-dropdown-item>
				</el-dropdown-menu>
			</div>
      
		</div>
    <el-menu
      :default-active="navLeftActive"
      class="el-menu-demo TopMenu"
      background-color="rgb(0 0 0 / 0%)"
      active-text-color="#000"
      active-background-color="#0c6279"
      text-color="#000"
      :router="true"
      :collapse-transition="false"
      @select="handleSelect"
      :collapse="isCollapse"
    >
      <template v-for="(item, index) in routerList">
        <el-submenu
          :index="item.path"
          :key="index"
          v-if="item.children.length != 1"
        >
          <template slot="title">
            <i class="marginRight svgI">
              <svg-icon :data_iconName="item.meta.icon" :className="item.meta.icon+'_icon'" class="svgIcon"
            /></i>
            <span slot="title">{{ $i18n.locale=='zh'?item.meta.title1:item.meta.title }}</span>
          </template>
          <el-menu-item
            :index="itm.path"
            v-for="(itm, inx) in item.children"
            :key="inx"
            >
            <i class="marginRight svgI">
              <svg-icon :data_iconName="itm.meta.icon" :className="itm.meta.icon+'_icon'" class="svgIcon"
            /></i>
            <span slot="title">{{ $i18n.locale=='zh'?itm.meta.title1:itm.meta.title }}</span>
            <!-- {{ itm.meta.title }} -->
            </el-menu-item
          >
        </el-submenu>
        <el-menu-item
          :index="item.children[0].path"
          v-if="item.children.length == 1"
          :key="index"
        >
            <i class="marginRight svgI">
              <svg-icon :data_iconName="item.meta.icon" :className="item.meta.icon+'_icon'" class="svgIcon"
            /></i>
            <span slot="title">{{ $i18n.locale=='zh'?item.meta.title1:item.meta.title }}</span>
        </el-menu-item>
      
      </template>
      </el-menu>
      <div class="language">
          <el-dropdown @command="changeLanguage">
          <span class="el-dropdown-link">
            <i class="marginRight svgI">
                  <svg-icon data_iconName="language" className="language_icon" class="svgIcon1"
                /></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="zh">CN  简体中文</el-dropdown-item>
            <el-dropdown-item command="en">US  English</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
        <!-- <el-dropdown class="peo">
        <div  @click="outerVisible = true"><i class="marginRight svgI">
              <svg-icon data_iconName="peo" className="peo_icon'" class="svgIcon"
            /></i><span slot="title">{{name}}</span></div>
				<el-dropdown-menu class="" slot="dropdown">
					<el-dropdown-item  @click.native="dialogVisible = true">Alter Password</el-dropdown-item>
					<el-dropdown-item  @click.native="logout">Logout</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown> -->
        <!-- <el-menu-item class="peo" @click.native="outerVisible = true"><i class="marginRight svgI">
              <svg-icon data_iconName="peo" className="peo_icon'" class="svgIcon"
            /></i><span slot="title">{{name}}</span></el-menu-item> -->
    
        <div class="alter">
          <el-dialog
            title="Alter Password"
            :visible.sync="dialogVisible"
            width="30%"
            @close="close"
            class="dialog"
            >
            <el-form ref="form" :model="form">
              <el-form-item label="Username:">
                <p class="user">{{name}}</p>
              </el-form-item>
              <el-form-item label="Password:">
                <el-input v-model="form.password1"></el-input>
              </el-form-item>
              <el-form-item label="Confirm Password:">
                <el-input v-model="form.password2"></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">Cancel</el-button>
              <el-button type="primary" @click="changePad">Confirm</el-button>
            </span>
          </el-dialog>
      </div>
  </div>
</template>

<script>
import { getFasta } from "@/api/dataList";
export default {
  name: "MenuTab",
  props: {
    isCollapse: {
      type: Boolean,
      default: true,
      
    },
  },
  data() {
    return {
      routerList: [],
      name:this.$Storage.getItem("adm_name"),
      dialogVisible:false,
      outerVisible:false,
      form: {
          username: '',
          password1: '',
          password2: '',
        }
    };
  },
  components: {
    //引入模块
  },
  methods: {
    changeLanguage(command){
      // console.log(command)
      this.$i18n.locale=command  //设置中英文模式
      localStorage.setItem('languageSet',this.$i18n.locale)  //将用户设置存储到localStorage以便用户下次打开时使用此设置
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      if(this.$route.path==key){
        location.reload()
        this.$router.go(0)
      }
      
    },
    getMenuLinks: function () {
      var t = this.$router.options.routes.filter(function (val, index, arr) {
        // return index >= 2;
        if(val.name!="detail"){
          return val.children;
        }
      });
      this.routerList = t;
      // console.log(t)
      //t returns a vue object instance
      // return t._children ;
      // did not know how to iterate this
    },
    // 修改密码
    changePad(){
      var data = {
        // Authorization:localStorage.getItem("Admin-Token"),
        new_password1:this.form.password1,
        new_password2:this.form.password2
      }
      getFasta("/auth/password/change/",data).then((response)=>{
        console.log(response,"alter")
        if(response.data.detail){
          this.$message.success(response.data.detail)
          this.dialogVisible=false
        }else{
          this.$message.error("Failed to modify password!")
          this.dialogVisible=true
        }
      })
    },
    //关闭弹窗清除数据
    close(){
      this.form.password1=''
      this.form.password2=''
    },
    /* 退出 */
    logout(){
      this.$Storage.clear()
      this.$router.push({path:"/login"})
    }
  },
  watch: {
    // watch擅长处理的场景：一个数据影响多个数据
  },
  computed: {
    // computed擅长处理的场景：一个数据受多个数据影响
    navLeftActive: function () {
      console.log(this.$route);
      const { meta, path } = this.$route;
      console.log(path, 111);
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path; //"/" + path.split("/")[1];
    },
  },
  beforeCreate: function () {
    // 在实例初始化之后，数据观测(data observer) 和 event/watcher 事件配置之前被调用。
  },
  created: function () {
    // 实例已经创建完成之后被调用。在这一步，实例已完成以下的配置：数据观测(data observer)，属性和方法的运算， watch/event 事件回调。然而，挂载阶段还没开始，el 属性目前不可见。
  },
  beforeMount: function () {
    // 在挂载开始之前被调用：相关的 render 函数首次被调用。
  },
  mounted: function () {
    // 编译好的HTML挂载到页面完成后执行的事件钩子
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
    // 此钩子函数中一般会做一些ajax请求获取数据进行数据初始化
    this.getMenuLinks();
  },
  beforeUpdate: function () {
    // 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
  },
  updated: function () {
    // 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
    // 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。然而在大多数情况下，你应该避免在此期间更改状态，因为这可能会导致更新无限循环。
    // 该钩子在服务器端渲染期间不被调用。
  },
  beforeDestroy: function () {
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
  },
  destroyed: function () {
    // Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 该钩子在服务器端渲染期间不被调用。
  },
};
</script>
<style scoped>
.Main{
  display: flex;
flex-direction: column;
position: relative;
}
.Main >>> .el-menu-item.is-active {
  background-color: #0c6279 !important;
}
.admin{
  width: 100%;
  padding: 10px 0;
}
.admin img{
  width: 70%;
  margin-left: 15%;
}
.el-avatar{
  margin-top: 16px;
  background: #c0c4cc8c;
}
.el-avatar>img {
    height: 38%;
    margin: 10px auto;
}
.peo{
  position: absolute;
  bottom: 20px;
  left: 20px;
  height: 20px;
  width: 100%;
}
.TopMenu {
  border: none;
  text-align: left;
  height: calc(100% - 120px);
  overflow-y: auto;
    overflow-x: hidden;
}
.TopMenu >>> .el-submenu__title i {
  /* color: #000; */
}
.TopMenu:not(.el-menu--collapse) {
  /* width: 210px; */
  /* min-height:880px; */
   /* height: calc(100% - 70px); */
   height: calc(100% - 130px);/* no */
}
.svgIcon{
  width: 18px !important;
  height: 25px !important;
}
.svgI{
  font-size: 20px;
}
.rightMenuBox{
  width: 100%;
  margin: 0 auto;
}
.alter>>>.el-input{
  width: 70%;
}
.alter>>>.el-form-item__label{
  width: 28%;
}
.dialog>>>.el-dialog__header{
  background: linear-gradient(90deg
, #198da6c4, #596fdcc7);
  height: 20px;
}
.alter>>>.el-dialog__title{
  color: white;
}
.alter .user{
  display: inline-block;
  margin: 0;
  width: 18%;
  background: #409eff;
  border-radius: 14px;
  color: white;
  text-align: center;
}
.alter>>>.el-dialog__headerbtn .el-dialog__close{
  color: white;
}
/* .outer>>>.el-dialog__body{
  display: flex;
    justify-content: space-evenly;
} */
.language{
  margin-left: 8%;
}
.svgIcon1{
  width: 30px;
  height: 30px;
}
</style>
<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
</style>
<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <keep-alive ><!-- :include="cachedViews" -->
        <router-view @navChange="navChange"/><!-- :key="key" -->
      </keep-alive>
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
   /*  cachedViews() {
      return this.$store.state.tagsView.cachedViews
    },
    key() {
      return this.$route.fullPath
    } */
  },
   methods: {
    // 组件的方法
    navChange(e){
      console.log(e)
      this.$emit("navChange",e)
    },
   }
}
</script>

<style scoped>
.app-main {
  /*84 = navbar + tags-view = 50 +34 */
  height: calc(100vh - 70px);
  width: 100%;
  position: relative;
  /* overflow: hidden; */
}
</style>



import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout 竖向*/
import Layout from '@/views/layout/Layout'
/* abeam 横向*/
import Abeam from '@/views/abeam/Layout'


export const routerMap = {
  Layout: () => Layout,

}
export const constantRouterMap = [
  //首次加载时先走登录页
  {
    path: '/login',
    name: 'login',
    component: () => {
      return import("@/views/login/index")
    }
  },
  
  //路由重定向
  {
    path: '/',
    redirect: "/login"
    // redirect: "/home"
  },
/*   {
    path: '/',
    redirect: "/home"
    // redirect: "/home"
  }, */
  {
    path: '/detail',
    component: Layout,
    redirect: 'detail',
    name: 'detail',
    alwaysShow: false,
    meta: {
      title: 'detail',//标题
      title1: '详情',//标题
      icon: 'home',
    },
    children: [
      {
        path: '/detail',
        component: () => import('@/views/pages/detail'),
        name: 'detail',
        meta: { title: 'detail'}
      }
    ]
  },
  // 没有下拉菜单，页面卸载children中
  {
    path: '/homeView',
    component: Abeam,
    // redirect: 'home',
    name: 'homeView',
    alwaysShow: true,
    meta: {
      title: 'Home',//标题
      title1: '首页',
      icon: 'home'//图标
    },
    children: [
      {
        path: '/home',
        component: () => import('@/views/pages/home'),
        name: 'home',
        meta: { title: 'Home',title1: '首页', }
      },
    ]
  },
  {
    path: '/JbrowseView',
    component: Layout,
    redirect: 'Jbrowse',
    name: 'JbrowseView',
    alwaysShow: true,
    meta: {
      title: 'Jbrowse',//标题
      title1: '基因浏览器',
      icon: 'browse'//图标
    },
    children: [
      {
        path: '/Jbrowse',
        component: () => import('@/views/pages/Jbrowse'),
        name: 'Jbrowse',
        meta: { title: 'Jbrowse', title1: '基因浏览器',}
      },
    ]
  },
  {
    path: '/browseView',
    component: Layout,
    redirect: 'Function',
    name: 'browseView',
    alwaysShow: true,
    meta: {
      title: 'Browse',//标题
      title1: '信息',//标题
      icon: 'search'//图标
    },
    children: [
      {
        path: '/Function',
        component: () => import('@/views/pages/search'),
        name: 'Function',
        meta: { title: 'Functional Annotation',title1: '查询', icon:'jiyinxin'}
      },{
        path: '/pathway',
        component: () => import('@/views/pages/pathway'),
        name: 'pathway',
        meta: { title: 'Pathway infomation',title1: '信号传导途径', icon: 'pathway'}
      },{
        path: '/Transposable-Elements',
        component: () => import('@/views/pages/TE'),
        name: 'Transposable Elements',
        meta: { title: 'Transposable Elements', title1: '转位因子',icon: 'te'}
      }
    ]
  },
  {
    path: '/AnalysisView',
    component: Layout,
    redirect: 'GeneCoExpression',
    name: 'AnalysisView',
    alwaysShow: true,
    meta: {
      title: 'Analysis',//标题
      title1: '分析',//标题
      icon: 'biaoxingshuju'//图标
    },
    children: [
      {
        path: '/GeneCoExpression',
        component: () => import('@/views/pages/GeneCoExpression'),
        name: 'Gene Co-Expression',
        meta: { title: 'Gene Co-Expression',title1: '基因共表达', icon: 'expression'}
      },{
        path: '/Deseq2',
        component: () => import('@/views/pages/Deseq2'),
        name: 'Deseq2',
        meta: { title: 'Deseq2', title1: '差异基因分析',icon: 'deseq2'}
      },{
        path: '/KEGG',
        component: () => import('@/views/pages/KEGG'),
        name: 'KEGG',
        meta: { title: 'KEGG Enrichment',title1: 'KEGG富集', icon: 'kegg'}
      },{
        path: '/GO',
        component: () => import('@/views/pages/GO'),
        name: 'GO',
        meta: { title: 'GO Enrichment',title1: 'GO富集', icon: 'go'}
      },
    ]
  },
  /* {
    path: '/BlastView',
    component: Layout,
    redirect: 'Blast',
    name: 'BlastView',
    alwaysShow: true,
    meta: {
      title: 'Blast',//标题
      icon: 'blast'//图标
    },
    children: [
      {
        path: '/Blast',
        component: () => import('@/views/pages/Blast'),
        name: 'Blast',
        meta: { title: 'Blast', }
      },
    ]
  }, */
/*   {
    path: '/sequenceView',
    component: Layout,
    redirect: 'sequence',
    name: 'sequenceView',
    alwaysShow: true,
    meta: {
      title: 'Sequence',//标题
      icon: 'sequence'//图标
    },
    children: [
      {
        path: '/sequence',
        component: () => import('@/views/pages/sequence'),
        name: 'sequence',
        meta: { title: 'Sequence', }
      },
    ]
  }, */
/*   {
    path: '/searchView',
    component: Layout,
    redirect: 'search',
    name: 'searchView',
    alwaysShow: true,
    meta: {
      title: 'Search',//标题
      icon: 'search'//图标
    },
    children: [
      {
        path: '/search',
        component: () => import('@/views/pages/search'),
        name: 'search',
        meta: { title: 'Search', }
      },
    ]
  }, */
  {
    path: '/toolsView',
    component: Layout,
    redirect: 'Blast',
    name: 'toolsView',
    alwaysShow: true,
    meta: {
      title: 'Tools',//标题
      title1: '工具',//标题
      icon: 'tools'//图标
    },
    children: [
      
      /* {
        path: '/tools/Transposable-Elements',
        component: () => import('@/views/pages/TE'),
        name: 'Transposable Elements',
        meta: { title: 'TEs', }
      } *//* ,{
        path: '/tools/KEGG',
        component: () => import('@/views/pages/KEGG'),
        name: 'KEGG',
        meta: { title: 'KEGG', }
      },{
        path: '/tools/GO',
        component: () => import('@/views/pages/GO'),
        name: 'GO',
        meta: { title: 'GO', }
      }, */
      {
        path: '/Blast',
        component: () => import('@/views/pages/Blast'),
        name: 'Blast',
        meta: { title: 'Blast', title1: '序列比对', icon: 'blast1'}
      },
      {
        path: '/heatmap',
        component: () => import('@/views/pages/heatmap'),
        name: 'heatmap',
        meta: { title: 'Heatmap', title1: '热图', icon: 'analysis'}
      },{
        path: '/MSA',
        component: () => import('@/views/pages/MSA'),
        name: 'MSA',
        meta: { title: 'MSA', title1: '多序列比对', icon: 'msa'}
      },
      {
        path: '/Venn',
        component: () => import('@/views/pages/Venn'),
        name: 'Venn',
        meta: { title: 'Venn', title1: '韦恩图', icon: 'venn'}
      },
      {
        path: '/sequence',
        component: () => import('@/views/pages/sequence'),
        name: 'sequence',
        meta: { title: 'Sequence Fetch',title1: '序列提取',  icon: 'sequence1'}
      },
      /* {
        path: '/tools/Primer3',
        component: () => import('@/views/pages/Primer3'),
        name: 'Primer3',
        meta: { title: 'Primer3', }
      }, */
      /* {
        path: '/pathway',
        component: () => import('@/views/pages/pathway'),
        name: 'pathway',
        meta: { title: 'Pathway', }
      }, */
      /* {
        path: '/GeneCoExpression',
        component: () => import('@/views/pages/GeneCoExpression'),
        name: 'Gene Co-Expression',
        meta: { title: 'Gene Co-Expression', }
      }, */
      /* {
        path: '/Deseq2',
        component: () => import('@/views/pages/Deseq2'),
        name: 'Deseq2',
        meta: { title: 'Deseq2', }
      }, */
    ]
  },
  /* {
    path: '/pathwayView',
    component: Layout,
    redirect: 'pathway',
    name: 'pathwayView',
    alwaysShow: true,
    meta: {
      title: 'pathway',//标题
      icon: 'pathway'//图标
    },
    children: [
      {
        path: '/pathway',
        component: () => import('@/views/pages/pathway'),
        name: 'pathway',
        meta: { title: 'pathway', }
      },
    ]
  }, */
  {
    path: '/downloadView',
    component: Layout,
    redirect: 'download',
    name: 'downloadView',
    alwaysShow: true,
    meta: {
      title: 'Download',//标题
      title1: '下载',//标题
      icon: 'download'//图标
    },
    children: [
      {
        path: '/download',
        component: () => import('@/views/pages/download'),
        name: 'download',
        meta: { title: 'Download',title1: '下载', }
      },
    ]
  },
  // 有下拉菜单，写在children中
 /*  {
    path: '/home1',
    component: Layout,
    // redirect: 'area',
    name: 'anti_channel',
    alwaysShow: true,
    meta: {
      title: '其他页面',//标题
      icon: 'home'//图标
    },
    children: [
      {
        path: '/home1/mm',
        component: () => import('@/views/login/index'),
        name: 'mm',
        meta: { title: '测试2', }
      },
      {
        path: '/home1/ceshi',
        component: () => import('@/views/login/index'),
        name: 'ceshi',
        meta: { title: '测试3', }
      },
    ]
  } */
 
  // {
  //	 path: '/404',
  //	 component: () => import('@/views/errorPage/404'),
  //	 hidden: true
  // },
  // {
  //	 path: '/401',
  //	 component: () => import('@/views/errorPage/401'),
  //	 hidden: true
  // }
]

export default new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRouterMap
})

export const asyncRouterMap = [
  /** When your routing table is too long, you can split it into small modules**/
]

